<template>
    <div>
        <div style="margin: 20px 0px 20px 0px; padding: 20px">

            <el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create', module_code)"> {{ $i18n.t("common.Add1") }}</el-button>

        </div>
        <el-table ref="pagedata" :data="pageData.list" border style="width: 100%">
            <el-table-column prop="shoppingRebateId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="countryCode"  :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dict.getDictValue('countrys', scope.row.countryCode) }}
                </template>
            </el-table-column>
            <el-table-column prop="rates" :label='$i18n.t("common.Rebateratio")' align="center" show-overflow-tooltip>
                <template slot-scope="scope"> {{ scope.row.rates }}% </template>
            </el-table-column>

			<!--
            <el-table-column prop="startTime" l:label='$i18n.t("common.Rebatestarttime")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-if="scope.row.startTime"> {{ scope.row.startTime | formatDate }}<br /> </span>
                </template>
            </el-table-column>
            <el-table-column prop="endTime" :label='$i18n.t("common.Rebateendtime")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.endTime"> {{ scope.row.endTime | formatDate }}<br /> </span>
                </template>
            </el-table-column>

			-->
			<el-table-column prop="status" :label='$i18n.t("common.Enableornot")'   align="center" show-overflow-tooltip>
                <template slot-scope="scope"> 
					<el-switch
					  	v-model="scope.row.status"
					  	active-color="#13ce66"
                    	inactive-color="#ff4949"
					  	disabled>
					</el-switch>
                </template>
            </el-table-column>
 
            <el-table-column prop="updateTime" :label='$i18n.t("common.RecentOperationTime")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.updateTime"> {{ scope.row.updateTime | formatDate }}<br /> </span>
                </template>
            </el-table-column>
            <el-table-column prop="updateUserName" :label='$i18n.t("common.RecentOperator")'  align="center" show-overflow-tooltip></el-table-column>
          
            <el-table-column :label='$i18n.t("common.operation")'  align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                    
					<el-tooltip  :disabled="!scope.row.buttonEdit" effect="dark"  :content='$i18n.t("common.PleaseCancelTheAssociationInRebateProductPoolFirst")' placement="top">
						<el-button :disabled="scope.row.buttonEdit"  @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update', module_code)"
                        > {{ $i18n.t("common.edit") }}</el-button>
					</el-tooltip>


                    <el-button @click="del(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete', module_code)"
                        >{{ $i18n.t("common.delete") }}</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.page"
                :page-size="listQuery.limit"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            >
            </el-pagination>
        </div>
        <el-dialog
            :title='isEdit ? $i18n.t("common.edit") : $i18n.t("common.Add1")'
            :visible.sync="dialogAddVisible"
            :before-close="closeDialog"
            :close-on-click-modal="false"
        >
            <div v-if="dialogAddVisible">
                <add-dialog :isEdit="isEdit" :formListQuery="formListQuery" @closeDialog="closeDialog" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
	import TabsDialog from '@/components/common/TabsDialog.vue';
	import cache from '@/utils/cache';
	import config from '@/utils/config';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import AddDialog from './components/AddDialog.vue';
	import {formatDate} from '@/utils/date';
	const defaultListQuery={
		"page": 1,
		"limit": 10
	}
	const defaultFormListQuery={
        status: 1, //0禁用 1启用
        countryCode: '',
        rates: 15
        // time: [],
        // startTime: null,
        // endTime: null
    }
	export default {
		components: {
			TabsDialog,
			FilterContainer,
			AddDialog
		},
		name: 'goods',
		data() {
			return {
				module_code:config.module_code.classAdmin,
				//编辑or新增
				isEdit: false,
				ossHost: process.env.VUE_APP_OSS_Head,
				//行信息
				rowInfo:{},
				langValList:[],
				//变体
				options: [],
				//关联列表
				relationList:[],
				//多语言ID
				rowGoodsClassifyId:"",
				listQuery:Object.assign({}, defaultListQuery),
				formListQuery:Object.assign({}, defaultFormListQuery),	
				pageData:{
				},
				langTitle:"",
				//选中的tree
				checkedKeys:[],
				checkedKeys1:[],
				//组件标签是否显示
				arg:{
					name:true,
					enable:true,
					brand:true
				},
				argFrom2: {
					parameterName:true,
					parameter:true					
				},
				placeholder:{
					name:this.$i18n.t("common.Categoryname1") 
				},
				defaultProps:{
					label:'title',
					id:'parameterGroupId',
					children:'parameterItems',
					disabled:function(data,node){
					if(data.parameterItems&&data.parameterItems.length>0){
					    return true
					    }else{
					        return false            
					    }        
					}    			            
				},
				//选中的参数
				treeCheckedList:[],
				//关联参数
				parameterGroupTitle:"",
				dialogAddVisible:false,
				dialogLanVisible:false,
				dialogRelaMajVisible:false,
			};
		},
		filters:{
			  formatDate(time,type='yyyy-MM-dd hh:mm:ss'){
			    if(time==null||time===''){
			      return 'N/A';
			    }
			    let date = new Date(time);
			    return formatDate(date,type)
			  },
		},
		mounted() {
			this.handleSearchList()
		},
		watch: {
			$route(to, from) { 
				// console.log('to',to)
				if (to.path === '/b2c/rebate/index') {
					this.handleSearchList();
				}
			}
		},
		methods: {
			//搜索列表
			handleSearchList(){											
				this.$store
					.dispatch('getRebateList', this.listQuery)
					.then(res => {					
						this.pageData=res
						console.log('this.pageData',this.pageData)
					});
			},
			add(){
				this.isEdit=false
				this.dialogAddVisible=true
			},
			edit(row){
				this.isEdit=true
				// let type='yyyy-MM-dd hh:mm:ss'
				// row.time=[]
				let data = Object.assign({}, row)
				data.status=row.status==true?1:0
				this.formListQuery=Object.assign({}, data)
				// if(row.startTime){
				// 	row.time.push(this.$options.filters['formatDate'](row.startTime,type))
				// }
				// if(row.endTime){
				// 	row.time.push(this.$options.filters['formatDate'](row.endTime,type))
				// }	
				this.dialogAddVisible=true							
			},
			handleCurrentChange(val) {
				this.listQuery.page = val;
				this.handleSearchList();
			},
			del(row){
				this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
				//   confirmButtonText: '确定',
				//   cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					this.$store
					  .dispatch("delRebate", {
						  id:row.shoppingRebateId,
					  })
					  .then((res) => {
					    if (res != null) {
							this.handleSearchList();
					    	this.$message({
					    	  type: 'success',
					    	  message:   this.$i18n.t("common.Successfullydeleted")
					    	});
					    }
					  });
				});
			},
			
			//关闭窗口
			closeDialog(){
				this.dialogAddVisible=false
				this.formListQuery=Object.assign({}, defaultFormListQuery)
				this.handleSearchList()
			},
		}
	};
</script>
<style scoped>
.filter-layout {
}

.input-width {
    width: 203px;
}

.down-template {
    text-decoration: underline;
    color: rgb(38, 166, 222);
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    margin: 20px 0px 0px 10px;
    cursor: pointer;
}
.ruku {
    color: #000000;
}
</style>
