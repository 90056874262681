<template>
     
    <div>
        <el-form :model="formListQuery" :rules="rules" ref="variantFrom">
            <el-form-item  :label='$i18n.t("common.country") ' prop="countryCode" :label-width="formLabelWidth">
                <el-select v-model="formListQuery.countryCode" :placeholder='$i18n.t("common.Pleaseselectacountry") '>
                    <el-option v-for="(item, index) in countryCode" :key="index" :label="item.name" :value="item.code"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Rebateratio") ' prop="rates" :label-width="formLabelWidth">
                <el-input
                    v-model="formListQuery.rates"
                    oninput="value=value.replace(/[^\d]/g,'');if(value<=0)value=1;if(value>100)value=100;"
                    class="input-width"
                    :placeholder='$i18n.t("common.PleaseEnterTheRebateRatio") '
                ></el-input
                >%
            </el-form-item>

            <!--
            <el-form-item :label='$i18n.t("common.Rebatetime") ' prop="time" :label-width="formLabelWidth">

                <el-date-picker
                    v-model="formListQuery.time"
                    clearable
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :range-separator='$i18n.t("common.to")'
                    :start-placeholder='$i18n.t("common.starttime")'
                    :end-placeholder='$i18n.t("common.Endtime")'
                >
                </el-date-picker>
            </el-form-item>

            -->
            <el-form-item :label='$i18n.t("common.Enableornot") ' :label-width="formLabelWidth">

                <el-switch
                    v-model="formListQuery.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
        </div>
    </div>
</template>
<script>
import dictionaryUtil from '@/utils/dictionaryUtil';
export default {
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
		formListQuery:{
            type: Object,
            default:()=>{
				return {
					status: 1, //0禁用 1启用
					countryCode: '',
					rates: 15
					// time: [],
					// startTime: null,
					// endTime: null
				}
			}
        },
    },
    data() {
        return {
            countryCode: dictionaryUtil.getDict('countrys'),
            formLabelWidth: '160px',
            //选中数量
            rules: {
                countryCode: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Pleaseselectacountry"),
                        trigger: 'change'
                    }
                ],
                rates: [
                    {
                        required: true,
                        message: this.$i18n.t("common.Rebateratio"),
                        trigger: 'blur'
                    }

                ]
                // ,
                // time: [
                //     {
                //         required: true,
                //         message: '请选择返利时间',
                //         trigger: 'change'
                //     }
                // ]
            }
        };
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm( this.$i18n.t("common.Whethertosubmitdata"),   this.$i18n.t("common.prompt"), {
                        // confirmButtonText: '确定',
                        // cancelButtonText: '取消',
                        type: 'warning',
                        beforeClose: (action, ctx, close) => {
                            if (action !== 'confirm') {
                                close();
                                return;
                            }
                            ctx.confirmButtonLoading = true;
                            // this.formListQuery.startTime = new Date(this.formListQuery.time[0]).getTime();
                            // this.formListQuery.endTime = new Date(this.formListQuery.time[1]).getTime();
                            if (this.isEdit) {
                                this.$store.dispatch('postUpdateRebate', this.formListQuery).then((res) => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullymodified"),
                                        type: 'success',
                                        duration: 1000
                                    });
									ctx.confirmButtonLoading = false;
									close()                                 
									this.closeDialog();
                                }).catch((err) => {
                                    setTimeout(() => {
                                        ctx.confirmButtonLoading = false;
                                    }, 500);
                                });
                            } else {
                                this.$store.dispatch('postAddRebate', this.formListQuery).then((res) => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullysubmitted"),
                                        type: 'success',
                                        duration: 1000
                                    });
									ctx.confirmButtonLoading = false;
									close()
									this.closeDialog();
                                }).catch((err) => {
                                    setTimeout(() => {
                                        ctx.confirmButtonLoading = false;
                                    }, 500);
                                });
                            }
                        }
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: this.$i18n.t("common.Canceled")
                        });
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Verificationfailed"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        }
    },
    mounted() {
	}
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

.avatar {
    height: 178px;
    display: block;
}
.uploadTitle {
    width: 120px;
    text-align: end;
    padding-right: 12px;
    box-sizing: border-box;
}
/* .uploadTitle:before {
	    content: '*';
	    color: #F56C6C;
	    margin-right: 4px;
	} */
.input-width {
    width: 205px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid rgba(234, 234, 234, 1);
    width: 80%;
}
.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    height: 100%;
    background-color: #ececec;
    padding: 0px 0px 0px 10px;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    background-color: #55aaff;
    color: white;
}
.content2 {
    width: auto;
}

.content2-sub {
    font-size: 15px;
    line-height: 15px;
    margin: 10px 0px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
