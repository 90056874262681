var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.country"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$i18n.t("common.Pleaseselectacountry")
                  },
                  model: {
                    value: _vm.formListQuery.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "countryCode", $$v)
                    },
                    expression: "formListQuery.countryCode"
                  }
                },
                _vm._l(_vm.countryCode, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Rebateratio"),
                prop: "rates",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  oninput:
                    "value=value.replace(/[^\\d]/g,'');if(value<=0)value=1;if(value>100)value=100;",
                  placeholder: _vm.$i18n.t("common.PleaseEnterTheRebateRatio")
                },
                model: {
                  value: _vm.formListQuery.rates,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "rates", $$v)
                  },
                  expression: "formListQuery.rates"
                }
              }),
              _vm._v("%\n        ")
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formListQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "status", $$v)
                  },
                  expression: "formListQuery.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }