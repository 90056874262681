<template>
    <div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :placeholder="placeholder" :multiple='multiple' :dictionary="dictionary" @handleSearchList='handleQuery' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 10px 0px 10px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)"> {{ $i18n.t("common.Add1") }}</el-button>
		</div>
        <el-table
            ref="pagedata"
            :data="pageData.list"
			height="calc(100% - 215px)"
            border
            style="width: 100%"         
        >
            <el-table-column prop="couponId" label="ID" align="center" show-overflow-tooltip> </el-table-column>


			<el-table-column prop="userType" :label="$t('common.ApplicableUserType')" align="center" width="150px" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.userType == 'ALL'">
						{{ $dict.getDictValue('user_type', 'NEW') + ' , ' + $dict.getDictValue('user_type', 'OLD') }}
					</span>
					<span v-else> {{ $dict.getDictValue('user_type', scope.row.userType) }}</span>
				</template>  	 
			</el-table-column>

			<el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" show-overflow-tooltip>

				<template slot-scope="scope">
					<span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }}<br /> </span>
				</template>
			</el-table-column>
			<el-table-column prop="type1"  :label='$i18n.t("common.Discounttype")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.name")' align="center" show-overflow-tooltip></el-table-column>	
			<el-table-column prop="isFixedCode1" :label='$i18n.t("common.Istheexchangecodefixed")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="quantity" :label='$i18n.t("common.quantity")'  align="center" show-overflow-tooltip> </el-table-column> 
			<el-table-column prop="receivedQuantity" :label='$i18n.t("common.Received")' align="center" show-overflow-tooltip> </el-table-column>
			<el-table-column prop="usedQuantity" :label='$i18n.t("common.Used")' align="center" show-overflow-tooltip> </el-table-column>
			<el-table-column prop="discount" :label="`${$t('common.money')}/${$t('common.discount')}`" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.type==2 && scope.row.currencyCode">
						<dataText v-model='scope.row.currencyCode' :codeType="'currency_code'">
							{{ scope.row.discount1 }}
						</dataText>
					</span>
					<span v-else-if="scope.row.type==1 && scope.row.currencyCode">{{ scope.row.discount }}%<br /> </span>
				</template>
			</el-table-column>
			<el-table-column prop="describe" :label='$i18n.t("common.explain")' align="center" show-overflow-tooltip> </el-table-column>
			<el-table-column prop="status1" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span :style="{color:scope.row.statusColor}">{{scope.row.status1}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="price" :label='$i18n.t("common.Availableamount")' align="center" show-overflow-tooltip>
				    <template v-slot='scope'>
						<div v-if="scope.row.currencyCode">
							<dataText v-model='scope.row.currencyCode' :codeType="'currency_code'">
							    {{ scope.row.price }}
							</dataText>
						</div>		        
				    </template>            
			</el-table-column>
			
			<el-table-column prop="maxDiscountPrice" :label='$i18n.t("common.Maximumdeductionamount")' align="center" show-overflow-tooltip>	
				<template slot-scope="scope">
					<dataText v-model='scope.row.currencyCode' :codeType="'currency_code'">
					    {{ scope.row.maxDiscountPrice }}
					</dataText>
				</template>
			</el-table-column>
			<el-table-column prop="isOverlayDiscount1" :label='$i18n.t("common.Canitbestacked")' align="center" show-overflow-tooltip> </el-table-column>
			<el-table-column prop="status2" :label='$i18n.t("common.Enableornot")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<el-switch v-model="scope.row.status2"  active-color="#13ce66" inactive-color="#ff4949" @change="switchChange(scope.row)">
					</el-switch>
				</template>	
			</el-table-column>
			<el-table-column prop="startTime" :label='$i18n.t("common.starttime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.startTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="endTime" :label='$i18n.t("common.Endtime")' align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.endTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="updateUserName" :label='$i18n.t("common.Creator")' align="center" show-overflow-tooltip> </el-table-column>		
            <el-table-column :label='$i18n.t("common.operation")' align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t("common.edit") }}</el-button>
					<el-button @click="openCouponCodes(scope.row)" type="text" size="small">{{  $i18n.t("common.Redemptioncode") }}</el-button>
                    <el-button @click="handleDelete(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{  $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" v-if="pageData.total!=0">
            <el-pagination		
                @current-change="handleCurrentChange"
                :current-page.sync="listQuery.page"
                :page-size="listQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
        <el-dialog :title="isEdit?$t('common.EditCoupon'):$t('common.AddCoupon')" :visible.sync="dialogFormVisible" :before-close="closeDialog" :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<add-update-dialog :formListQuery='formListQuery' :isEdit='isEdit' :dialogId='dialogId' :dictionary='dictionary' @closeDialog='closeDialog' @handleSearchList="handleSearchList"/>
			</div>        
        </el-dialog>
		<el-dialog :title='$i18n.t("common.Redemptioncode")' :visible.sync="dialogFormTableVisible2" :close-on-click-modal="false" append-to-body :before-close='closeCouponCodeDialog'>
            <div v-if="dialogFormTableVisible2">
                <FormTableDialog ref='couponCodeForm' :argFrom="argFrom2" :dictionary="dictionary" :placeholderFrom="placeholderFrom2" :closeFooter='true' :isShowObjectFrom="isShowObjectFrom"
                                 @handleExport="handleExport" @handleSearchList='handleCouponCodeSearchList' @handleResetSearch="handleCouponCodeResetSearch">
                    <template v-slot:tableSlot>
                        <el-table ref="pagedata" :data="pageData2.list" border class="tableGoods"
                                  style="width: 100%">
                            <el-table-column prop="couponCodesId" :label='$i18n.t("common.number")'  align="center" show-overflow-tooltip> </el-table-column>
                            <el-table-column prop="code" :label='$i18n.t("common.Redemptioncode")' align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="isReceived1" :label='$i18n.t("common.Claimstatus")' align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="receivedTime" :label='$i18n.t("common.Collectiontime")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.receivedTime">{{scope.row.receivedTime | formatDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="isUsed1"  :label='$i18n.t("common.Usagestatus")' align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="usedTime" :label='$i18n.t("common.Usagetime")' align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.usedTime">{{scope.row.usedTime | formatDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="usedUserId" :label="$t('common.UseUserID')" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="usedOrderNo" :label='$i18n.t("common.Useordernumber")' align="center" show-overflow-tooltip></el-table-column>
                        </el-table>
                        <div class="block">
                            <el-pagination @current-change="handleCouponCodeCurrentChange" :current-page.sync="couponCodesQuery.page"
                                           :page-size="couponCodesQuery.size" layout="total, prev, pager, next, jumper" :total="pageData2.total">
                            </el-pagination>
                        </div>
                    </template>
                </FormTableDialog>
            </div>
		</el-dialog>
    </div>
</template>
<script>
	import {enumeration,randomString} from '@/utils/common2';
	import {formatDate} from '@/utils/date';
	import config from "@/utils/config.js";
	import AddUpdateDialog from './components/AddUpdateDialog.vue';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import FormTableDialog from '@/components/common/FormTableDialog.vue';
	import dataText from '@/components/common/DataText.vue'; 
	import i18n from '@/i18n/i18n.js'
    import dictionaryUtil from '@/utils/dictionaryUtil';
	const defaultCouponCodesQuery={
		"isReceived": '',
		"isUsed": '',
		"usedUserId":'',
        "code":'',
		"usedOrderNo":'',
		"page": 1,
		"limit": 10
	}
	const defaultFormListQuery={
		"title": "",
		"countryCode": "",
		"type": "1",
		"discount":null,
		"discount1":null,
		"isFixedCode":'0',
		"quantity":null,
		"price":null,
		"maxDiscountPrice":null,
		"isOverlayDiscount":'0',
		"isOverlayPromotion":'0',
		"isOverlayDistribution":'0',
		"time":'',
		"code":null,
		"startTime":'',
		"endTime":'',
		"describe":'',
		"status": "1",
		"times": "1",
		"status1": true,
		"couponGoodsIdList":[],
		"couponGoodsClassifiesIdList":[],
		"userType": [],
		"page": 1,
		"limit": 10
	}
	const defaultDictionary={
			// 国家
			country:dictionaryUtil.getDict('countrys'),
			jump:[{
				id:'1',
				name:i18n.t("common.discount"),
				value:'1'
			},{
				id:'2',
				name:i18n.t("common.Fullreduction"),
				value:'2'
			}],

			//是否叠加
			over:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:'1'
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:'0'
			}],
			//促销是否叠加
			isOverlayPromotion:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:'1'
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:'0'
			}],
			//分销是否叠加
			isOverlayDistribution:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:'1'
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:'0'
			}],
			//是否有固定码
			fixedCode:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:'1'
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:'0'
			}],
			status:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:true
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:false
			}],
			//领取状态
			isReceived:[{
				id:'1',
				name:i18n.t("common.Notclaimed"),
				value:'0'
			},{
				id:'2',
				name:i18n.t("common.Received"),
				value:'1'
			}],
			//使用状态
			isUsed:[{
				id:'1',
				name:i18n.t("common.notused"),
				value:'0'
			},{
				id:'2',
				name:i18n.t("common.Used"),
				value:'1'
			}],
			//促销
			promotion:[{
				id:'1',
				name:i18n.t("common.yes"),
				value:true
			},{
				id:'2',
				name:i18n.t("common.no"),
				value:false
			}],
			//促销
			yuqi:[{
				id:'1',
				name:i18n.t("common.Effective"),
				value:1
			},{
				id:'2',
				name:i18n.t("common.Comingsoon1"),
				value:2
			},{
				id:'3',
				name:i18n.t("common.Expired"),
				value:3
			}],
			state:[{
				id:'1',
				name:i18n.t("common.Listed"),
				value:true
			},{
				id:'2',
				name:i18n.t("common.Notlisted"),
				value:false
			}],
			userType:dictionaryUtil.getDict('user_type'),
			classifyTree:[]
		};
		export default {
		    components: { FilterContainer,AddUpdateDialog,FormTableDialog,dataText },
		    name: 'varoantAdmin',
		    data() {
		        return {
					module_code:config.module_code.coupon,
					multiple:false,
					//字典
					dictionary:Object.assign({}, defaultDictionary),
					dialogId:'',
		            //查询项
					listQuery:{
					  "queryString": "",
					  "countryCode": "",
					  "type": "",
					  "status": "",
					  "isFixedCode":"",
					  "validStatus":"",
					  "isOverlayDiscount": "",
					  "userType": "",
					  "page": 1,
					  "limit": 10
					},
					defaultListQuery:{
					  "queryString": "",
					  "countryCode": "",
					  "type": "",
					  "status": "",
					  "isFixedCode":"",
					  "validStatus":"",
					  "isOverlayDiscount": "",
					  "userType": "",
					  "page": 1,
					  "limit": 10
					},
					formListQuery:Object.assign({}, defaultFormListQuery),
					couponCodesQuery:Object.assign({}, defaultCouponCodesQuery),
		            searchData: {
		                searchStr: '',
		                saleCountry: [],
		                platformCode: [],
		                enabled: null,
		                arrayDate: [],
		                page: 1,
		                limit: 10
		            },
					//组件标签是否显示
					arg:{
						name:true,
						jumpType:true,
						country:true,
						over:true,
						fixedCode:true,
						yuqi:true,
						enable:true,
						multiple:true,
						userType:true
					},
					placeholder:{
						name:this.$i18n.t("common.IDCouponName"),
						jumpType: this.$i18n.t("common.Discounttype"),
						country: this.$i18n.t("common.Countryofuse"),
						state:this.$i18n.t("common.state")
					},
					argFrom2: {
						name: true,
						name2:true,
						name3:true,
						receiveState:true,
						useState:true
					},
					placeholderFrom2:{
						state:this.$i18n.t("common.Usagestatus"),
						exportText:this.$i18n.t("common.export"),
					},
					isShowObjectFrom:{
						export:true
					},
					//是否是整数
					isParse:false,
					//编辑or新增
					isEdit: false,
		            //表单数据
		            formItem: {},
		            platformList: [],
		            pageData: {},
					pageData2: {},
		            dialogFormVisible: false,
					dialogFormTableVisible2:false,
		            title: '',
		            ossHost: process.env.VUE_APP_OSS_Head,
		            //字典下拉框
		            brandDicts: [],
		            countrysDicts: [],
		            platformDicts: [],
		            webSiteUrl: process.env.VUE_APP_WEBSITE
		        };
		    },
			filters:{
			  formatDate(time,type='yyyy-MM-dd hh:mm:ss'){
			    if(time==null||time===''){
			      return 'N/A';
			    }
			    let date = new Date(time);
			    return formatDate(date,type)
			  },
			  enumeration(val){
				  return enumeration(val)
			  }
			},
		    mounted() {
				this.getGoodsClassifyTree()
				this.handleSearchList()				
		    },
		    methods: {
				//启用禁用
				switchChange(row){
					const data=new URLSearchParams()				
					data.append('couponId',row.couponId)			
					this.$store
						.dispatch('enableCoupon', data).then(res => {
							this.handleSearchList()
						})
				},
				getGoodsClassifyTree(){
					this.$store
						.dispatch('getGoodsClassifyTree', {})
						.then(res => {
							this.$set(this.dictionary,"classifyTree",res)				
						});
				},
				handleQuery(listQuery){
					this.listQuery.page = 1;
					this.handleSearchList(listQuery);
				},
				//搜索列表
				handleSearchList(listQuery){
					console.log(this.listQuery);
					console.log(listQuery);
					let type='yyyy-MM-dd hh:mm:ss'
					let beginTime=new Date().getTime()
					let endTime=new Date().getTime() +24 * 60 * 60 * 1000 -1
					let nowTime=new Date(new Date().toLocaleDateString()).getTime()
					let nowTime2=new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
					if(listQuery){
						let status=''
						if(listQuery.enable===true){
							status="1"
						}else if(listQuery.enable===false){
							status="0"
						}		
						this.listQuery={
							"queryString": listQuery.title,
							"countryCode": listQuery.country,
							"type": listQuery.jumpType,
							"status": status,
							"validStatus":listQuery.yuqi,
							"isFixedCode": listQuery.fixedCode,
							"isOverlayDiscount": listQuery.isOverlayDiscount,
							"userType": listQuery.userType,
							"page": this.listQuery.page,
							"limit": this.listQuery.limit
						}
					}						
					this.$store
						.dispatch('getCoupon', this.listQuery)
						.then(res => {											
							res.list.forEach((item,index)=>{
								//优惠券类型
								if(item.type=='1'){
									res.list[index].type1=this.$i18n.t("common.discount")
									res.list[index].discount=res.list[index].discount?(res.list[index].discount*100).toFixed(1):0
								}else if(item.type=='2'){
									res.list[index].type1=this.$i18n.t("common.Fullreduction")
									res.list[index].discount1=res.list[index].discount?res.list[index].discount.toFixed(1):0
								}
								//是否有固定兑换码
								if(item.isFixedCode=='0'){
									res.list[index].isFixedCode1=this.$i18n.t("common.Random")
								}else if(item.isFixedCode=='1'){
									res.list[index].isFixedCode1=this.$i18n.t("common.Fixed")					
								}
								if(parseInt(item.discount)==item.discount){
								    res.list[index].isParse=true
								}else{
									res.list[index].isParse=false
								}
								//状态
								if(item.startTime>nowTime){
									res.list[index].status1=this.$i18n.t("common.AboutToTakeeffect")
									res.list[index].statusColor='red'
								}else if(item.endTime<beginTime){
									res.list[index].status1=this.$i18n.t("common.Expired")
									res.list[index].statusColor='black'
								}else if(item.endTime>=nowTime && item.startTime<=nowTime2){
									res.list[index].status1=this.$i18n.t("common.Effective")
									res.list[index].statusColor='green'
								}
								// if(item.startTime>endTime){
								// 	res.list[index].status1='即将生效'
								// 	res.list[index].statusColor='red'
								// }else if(item.endTime<beginTime){
								// 	res.list[index].status1='已过期'
								// 	res.list[index].statusColor='black'
								// }else if(item.endTime>=endTime){
								// 	res.list[index].status1='有效'
								// 	res.list[index].statusColor='green'
								// }
								//是否可叠加
								if(item.isOverlayDiscount=='0'){
									res.list[index].isOverlayDiscount1=this.$i18n.t("common.NotStackable")
								}else if(item.isOverlayDiscount=='1'){
									res.list[index].isOverlayDiscount1=this.$i18n.t("common.Stackable")					
								}
								if(item.status=='0'){
									res.list[index].status2=false
								}else if(item.status=='1'){
									res.list[index].status2=true					
								}						
							})
							this.pageData=res
							console.log('this.listQuery',this.pageData)						
						});
				},
				//搜索列表
				handleSearchList2(listQuery){
                    if(listQuery){
                        this.couponCodesQuery.isReceived = listQuery.isReceived
                        this.couponCodesQuery.isUsed = listQuery.isUsed
                        this.couponCodesQuery.usedUserId = listQuery.title
                        this.couponCodesQuery.code = listQuery.title2
                        this.couponCodesQuery.usedOrderNo = listQuery.title3
                    }else {
                        this.couponCodesQuery.isReceived = ''
                        this.couponCodesQuery.isUsed = ''
                        this.couponCodesQuery.usedUserId = ''
                        this.couponCodesQuery.code = ''
                        this.couponCodesQuery.usedOrderNo = ''
                    }
					this.$store
						.dispatch('getCouponCodes', this.couponCodesQuery)
						.then(res => {					
							this.pageData2=res
							this.pageData2.list.forEach((item,index)=>{
								//领取状态
								if(item.isReceived=='0'){
									this.pageData2.list[index].isReceived1=this.$i18n.t("common.Notclaimed")
								}else if(item.isReceived=='1'){
									this.pageData2.list[index].isReceived1=this.$i18n.t("common.Received")			
								}
								//使用状态
								if(item.isUsed=='0'){
									this.pageData2.list[index].isUsed1=this.$i18n.t("common.notused")		
								}else if(item.isUsed=='1'){
									this.pageData2.list[index].isUsed1=this.$i18n.t("common.Used")				
								}else if(item.isUsed=='2'){
									this.pageData2.list[index].isUsed1=this.$i18n.t("common.Frozen")					
								}					
							})					
						});
				},
                //搜索列表
                handleCouponCodeSearchList(listQuery){
                    this.couponCodesQuery.page = 1
                    this.handleSearchList2(listQuery)
                },
				handleResetSearch(){
					this.listQuery.page=1
					this.$set(this.pageData,'page',1)
					this.formListQuery=Object.assign({}, defaultFormListQuery)
					this.handleSearchList(this.formListQuery)
				},	
				handleCouponCodeResetSearch(){
                    this.$refs.couponCodeForm.query = Object.assign({}, {});
					this.couponCodesQuery.page=1
					this.$set(this.pageData2,'page',1)
					this.handleSearchList2()
				},
				handleExport(listQuery){
                    const data={
                        "couponId":this.couponCodesQuery.couponId,
                        "fileName":this.$i18n.t("common.Redemptioncode")+'.xlsx'
                    }
                    if(listQuery){
                        data.isReceived = listQuery.isReceived
                        data.isUsed = listQuery.isUsed
                        data.usedUserId = listQuery.title
                        data.code = listQuery.title2
                        data.usedOrderNo = listQuery.title3
                    }

					this.$store.dispatch('exportCoupon', data);
				},
		        add() {
					this.isEdit=false
					this.dialogFormVisible=true
		        },
				
				//打开编辑
				edit(item){
					let row=Object.assign({},item)
					this.isEdit=true	
					this.dialogId=row.couponId		
					row.time=[]
					if(row.startTime && row.endTime){
						let type='yyyy-MM-dd hh:mm:ss'
						let startTime=this.$options.filters['formatDate'](row.startTime,type)
						let endTime=this.$options.filters['formatDate'](row.endTime,type)
						row.time.push(startTime)
						row.time.push(endTime)
					}
					row.isFixedCode=row.isFixedCode.toString()
					row.isOverlayDiscount=row.isOverlayDiscount.toString()
					row.isOverlayPromotion=row.isOverlayPromotion.toString()
					row.isOverlayDistribution=row.isOverlayDistribution.toString()
					row.type=row.type.toString()
					row.status==1?row.status1=true:row.status1=false
					this.formListQuery= Object.assign({},row)
					//处理适用用户类型复选框
					if (this.formListQuery.userType == 'ALL') {
						this.formListQuery.userType = ['NEW', 'OLD'];
					} else if (this.formListQuery.userType == 'NEW') {
						this.formListQuery.userType = ['NEW'];
					} else if (this.formListQuery.userType == 'OLD') {
						this.formListQuery.userType = ['OLD'];
					} else {
						this.formListQuery.userType = [];
					}
					this.dialogFormVisible=true
				},
				//打开兑换码
				openCouponCodes(row){				
					this.couponCodesQuery.couponId=row.couponId
					setTimeout(()=>{
                        this.couponCodesQuery.page=1
                        this.$set(this.pageData2,'page',1)
						this.handleSearchList2()
						this.dialogFormTableVisible2=true
					})				
				},
				//删除
				handleDelete(row){
					this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
					//   confirmButtonText: '确定',
					//   cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						console.log('row',row)
						this.$store
						  .dispatch("deleteCoupon", {
							  id:row.couponId,
						  })
						  .then((res) => {
						    if (res != null) {
								this.handleSearchList();
						    	this.$message({
						    	  type: 'success',
						    	  message:   this.$i18n.t("common.Successfullydeleted")
						    	});
						    }
						  });
					});
				},
				handleCurrentChange(val) {
					this.listQuery.page = val;
					this.handleSearchList();
				},
                handleCouponCodeCurrentChange(val) {
					this.couponCodesQuery.page = val;
					this.handleSearchList2(this.$refs.couponCodeForm.getAndSetQueryData());
				},
				//关闭窗口
				closeDialog(){
					this.dialogFormVisible=false
					this.formListQuery=Object.assign({}, defaultFormListQuery)
				},
                //关闭窗口
                closeCouponCodeDialog(){
                    this.dialogFormTableVisible2=false
                    this.couponCodesQuery = Object.assign({}, defaultCouponCodesQuery)
                }
		    }
		};
</script>
